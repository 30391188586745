import React from 'react';
import ArtifactIndex from './index/artifact-index.js';
import ExhibitIndex from './index/exhibit-index.js';
import PublicationIndex from './index/publication-index';
import MapsIndex from './index/maps-index';
import StorySingleIndex from './index/story-single-index';
import SpeeechesTranscriptsIndex from './index/speeches-transcripts-index';
import PressReleasesStatementsIndex from './index/press-releases-statements-index';
import FeaturedStoriesIndex from './index/featured-stories-index';
import HeroIndex from './index/hero-index';
import HeadquartersIndex from './index/headquarters-index';
import MapSearch from './map-search';
import { useMaps } from '@src/hooks/use-maps';

const SiteComponent = ({ block, pageContext }) => {
  return <>{useBlockComponent(block.attributes, block.innerBlocks, pageContext)[(block.name)] ?? null}</>;
};

export const useBlockComponent = (block, innerBlocks, pageContext) => ({
  'condor/search-input': {
    'ciaMaps': <MapSearch {...block} data={useMaps()} />
  }[block?.searchType],
  'condor/index': {
    'artifacts': <ArtifactIndex />,
    'exhibits': <ExhibitIndex />,
    'publications': <PublicationIndex />,
    'ciaMaps': <MapsIndex data={useMaps()} />,
    'stories': <StorySingleIndex />,
    'heroes': <HeroIndex />,
    'headquarters': <HeadquartersIndex />,
    'speechesTranscripts': <SpeeechesTranscriptsIndex />,
    'pressReleasesStatements': <PressReleasesStatementsIndex />,
    'featuredStories': <FeaturedStoriesIndex />
  }[block?.indexKey],
});

export default SiteComponent;
