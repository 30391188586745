import React, { useState, useMemo } from 'react';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import FadeInSection from '@shared/components/utils/fade-in-section';
import { SCROLL_IN_OFFSET } from '@shared/utils/constants';
import AZFilter from '@shared/components/index/filter/az-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import { UnmanagedPaginationControl } from '@shared/components/index/pagination-controls/pagination-controls';
import { useFilters } from '@shared/hooks/use-filters';
import CardMedia from '@shared/components/index/media-card/media-card';

const MapsIndex = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters, applyFilters] = useFilters();

  const { azFilter } = filters;
  const results = applyFilters(data);

  return (
    <FadeInSection wait={SCROLL_IN_OFFSET.fade_in_offset + SCROLL_IN_OFFSET.hero_offset}>
      <IndexLayout>
        <FilterMenu sticky={true}>
          <ResultLabel
            size={results.length}
            label='Maps'
            clearAction={() => {
              setCurrentPage(1);
              setFilters({ azFilter: null });
            }}
          />
          <AZFilter
            value={azFilter}
            onFilterChange={(value) => {
              setCurrentPage(1);
              setFilters({ azFilter: value });
            }}
            items={data}
            getItemValue={(item) => item.title}
          />
        </FilterMenu>
        <IndexContent>
          <UnmanagedPaginationControl
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            renderCallback={(items) => (
              <>
                {items?.map((item, index) => (
                  <CardMedia
                  key={index}
                  title={item.title}
                  image={item.featuredImage.node}
                  uri={item.uri}
                  layout={'thirds'}
                />
                ))}
              </>
            )}
            items={results}
            pageSize={12}
          />
        </IndexContent>
      </IndexLayout>
    </FadeInSection>
  );
};

export default MapsIndex;
