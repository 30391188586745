import { graphql, useStaticQuery } from 'gatsby';

export const useMaps = () => {
  return useStaticQuery(graphql`
    query MapsQuery {
      allWordpressWpTtResourcesMap(sort: {fields: title}) {
        nodes {
          title
          uri
          featuredImage {
            node {
              ...MediaItemFieldsW400
            }
          }
        }
      }
    }
  `)?.allWordpressWpTtResourcesMap?.nodes;
};
