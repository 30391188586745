import React from 'react';
import { graphql } from 'gatsby';
import * as he from 'he';
import Layout from '@shared/components/layout/layout';
import Seo from '@shared/components/layout/seo';
import PageBuilder from '@shared/components/page-builder/page-builder';
import { useBlockFlatten } from '@shared/hooks/use-block-flatten'
import { useGlobalFooter } from '@shared/hooks/use-global-footer';
import { CIA_TEMPLATE, CAREERS_TEMPLATE, CSI_TEMPLATE, TECH_TEMPLATE } from '@shared/utils/constants';
import { useBlockComponent } from '@components/site-component';

const PageTemplate = ({ data, pageContext }) => {
  const page = Object.values(data).find((item) => item !== null);
  const parentPage = page.parentDatabaseId !== null ?
    page.parentPage :
    pageContext.parentPage;
  const template = {
    cia: CIA_TEMPLATE,
    csi: CSI_TEMPLATE,
    tech: TECH_TEMPLATE,
  }[pageContext.header];
  let scriptData = null;

  if(page.title.indexOf('Frequently Asked Questions') > -1) {
    scriptData = {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[]};
    page.blocks?.[1].innerBlocks.forEach((block) => {
      if(block.attributes.title && block.innerBlocks?.length > 0) {
        let answer = '';
        block.innerBlocks.forEach((para) => { 
          if(para?.attributes?.content) {
            answer += '<p>' + he.encode(para.attributes.content, {'useNamedReferences': true}) + '</p>'
          }
        });
        scriptData.mainEntity.push({"@type": "Question", "name:": block.attributes.title, "acceptedAnswer": {"@type":"Answer","text": answer}});
      }
    });
    scriptData = JSON.stringify(scriptData);
  }
 
  return (
    <Layout
      template={template ?? CIA_TEMPLATE}
      title={page.title}
      metaData={page.seo}
      headerMenu={data[pageContext.header]?.menuItems.nodes ?? data.cia.menuItems.nodes}
      footerMenu={useGlobalFooter()}
      scriptData={scriptData}
    >
      <PageBuilder
        blocks={useBlockFlatten(page.blocks)}
        getSiteComponent={useBlockComponent}
        pageContext={{
          ...pageContext,
          parentPage, 
          baseUri: page.uri,
          tags: page?.tags,
        }}
      />
    </Layout>
  );
};

export default PageTemplate;

export const Head = ({ data, base }) => {
  const page = Object.values(data).find((item) => item !== null);
  return ( <Seo metaData={page.seo} base={base} title={page.title} /> );
}

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpPage(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }
    wordpressWpTtAbout(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }
    wordpressWpTtLegacy(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }
    wordpressWpTtArtifact(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }
    wordpressWpTtExhibit(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }
    wordpressWpTtContactReport(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }
    wordpressWpTtIntelTimeline(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }
    wordpressWpTtHeadquarters(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }
    wordpressWpTtHero(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }
    wordpressWpTtResource(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }    
    wordpressWpTtResourcesMap(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }
    wordpressWpTtPublication(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }
    wordpressWpTtStories(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...BlockContentFields
    }    
    wordpressWpTtStorySingle(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      tags: storyTags
      ...ParentFields
      ...BlockContentFields
    }
    cia: wordpressWpMenu(slug: {eq: "cia-menu"}) {
      ...Header
      menuItems {
        nodes {
          url
          uri
          path
          parentId
          parentDatabaseId
          label
          databaseId
          description
          childItems {
            nodes {
              id
              url
              uri
              path
              label
              parentDatabaseId
            }
          }
        }
      } 
    }
    csi: wordpressWpMenu(slug: {eq: "csi"}) {
      ...Header
      menuItems {
        nodes {
          url
          uri
          path
          parentId
          parentDatabaseId
          label
          databaseId
          description
          childItems {
            nodes {
              id
              url
              uri
              path
              label
              parentDatabaseId
            }
          }
        }
      } 
    }
    tech: wordpressWpMenu(slug: {eq: "tech"}) {
      ...Header
      menuItems {
        nodes {
          url
          uri
          path
          parentId
          parentDatabaseId
          label
          databaseId
          description
          childItems {
            nodes {
              id
              url
              uri
              path
              label
              parentDatabaseId
            }
          }
        }
      }
    }
  }
`;
