import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import FadeInSection from '@shared/components/utils/fade-in-section';
import { SCROLL_IN_OFFSET } from '@shared/utils/constants';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import PaginationControl from '@shared/components/index/pagination-controls/pagination-controls';
import CardMedia from '@shared/components/index/media-card/media-card';

const ExhibitIndex = ({ baseUri }) => {
  const data = useStaticQuery(graphql`
    query ExhibitsQuery {
      exhibits: allWordpressWpTtExhibit(sort: {fields: title}) {
        nodes {
          title
          uri
          featuredImage {
            node {
              ...MediaItemFields
            }
          }
        }
      }
    }
  `);
  const exhibits = data.exhibits.nodes;

  return (
    <FadeInSection wait={SCROLL_IN_OFFSET.fade_in_offset + SCROLL_IN_OFFSET.hero_offset}>
      <IndexLayout>
        <FilterMenu sticky={true}>
          <ResultLabel size={exhibits.length} label='Exhibit' />
        </FilterMenu>
        <IndexContent>
          <PaginationControl
            renderCallback={(item) => (
              <>
                {item?.map((exhibit, index) => (
                  <CardMedia
                    key={index}
                    title={exhibit.title}
                    image={exhibit.featuredImage.node}
                    uri={exhibit.uri}
                    layout={'thirds'}
                  />
                ))}
              </>
            )}
            items={exhibits}
            pageSize={12}
          />
        </IndexContent>
      </IndexLayout>
    </FadeInSection>
  );
};

export default ExhibitIndex;
