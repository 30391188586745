import React, { useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import FadeInSection from '@shared/components/utils/fade-in-section';
import { SCROLL_IN_OFFSET } from '@shared/utils/constants';
import OrderFilter from '@shared/components/index/filter/order-filter';
import CategoryFilter, { useCategoryFilter } from '@shared/components/index/filter/category-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import { UnmanagedPaginationControl } from '@shared/components/index/pagination-controls/pagination-controls';
import { useFiltersWithParams as useFilters } from '@shared/hooks/use-filters';
import CardMedia from '@shared/components/index/media-card/media-card';
import { useLocation } from '@gatsbyjs/reach-router';
import queryString from 'query-string';

const FeaturedStoriesIndex = () => {
  const data = useStaticQuery(graphql`
    query FeaturedStoriesQuery {
      items: allWordpressWpTtStorySingle(
        filter: {indexCategories: {eq: "Featured Story"}}
        sort: {fields: date, order: DESC}
      ) {
        nodes {
          title
          uri
          date
          prettyDate: date(formatString: "MMMM D, YYYY")
          indexCategories
          storyTags
          featuredImage {
            node {
              ...MediaItemFields
            }
          }
        }
      }
    }
  `);
  const items = data.items.nodes;

  const locationObj = useLocation();
  const params = queryString.parse(locationObj.search);

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters, applyFilters] = useFilters({
    tagFilter: { lambda: useCategoryFilter((item) => item.storyTags, true) },
  });

  const { orderFilter, categoryFilter } = filters;
  const results = applyFilters(items, { tagFilter: params?.tag });

  return (
    <FadeInSection wait={SCROLL_IN_OFFSET.fade_in_offset + SCROLL_IN_OFFSET.hero_offset}>
      <IndexLayout>
        <FilterMenu sticky={true}>
          <ResultLabel
            size={results.length}
            clearAction={() => {
              setFilters({ 
                orderFilter: null,
                categoryFilter: null,
              });
              navigate(locationObj.pathname);
            }}
          >
            {params?.tag && (
              <div className='label bold mb30'>
                Tags: {params.tag}
              </div>
            )}
          </ResultLabel>
          <OrderFilter
            value={orderFilter}
            onFilterChange={(value) => {
              setCurrentPage(1);
              setFilters({ orderFilter: value });
            }}
            getItemValue={(item) => item.date}
          />
          <CategoryFilter
            value={categoryFilter}
            onFilterChange={(value) => {
              setCurrentPage(1);
              setFilters({ categoryFilter: value });
            }}
            items={items}
            getItemValue={(item) => item.indexCategories.map((item) => item)}
            multipleCategories={true}
          />
        </FilterMenu>
        <IndexContent>
          <UnmanagedPaginationControl
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            renderCallback={(item) => (
              <>
                {item?.map((item, index) => (
                  <CardMedia
                    key={index}
                    title={item.title}
                    label={`Published ${item.prettyDate}`}
                    image={item.featuredImage.node}
                    uri={item.uri}
                    layout={'halves'}
                  />
                ))}
              </>
            )}
            items={results}
            pageSize={12}
          />
        </IndexContent>
      </IndexLayout>
    </FadeInSection>
  );
};

export default FeaturedStoriesIndex;
