import React, { useMemo } from 'react';
import { navigate } from 'gatsby';
import SearchInput from '@shared/components/search-input/search-input';

const MapSearch = ({ data, ...props }) => {
  const valueList = useMemo(() => data.map((item) => item.title), [data]);
  return (
    <SearchInput
      {...props}
      valueList={valueList}
      onSelection={(value) => {
        const match = data.find((item) => item.title.toLowerCase() === value.toLowerCase());
        if (match)
          navigate(match.uri);
        return !!match;
      }}
    />
  );
};

export default MapSearch;
