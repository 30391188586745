import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import * as he from 'he';
import FadeInSection from '@shared/components/utils/fade-in-section';
import { SCROLL_IN_OFFSET } from '@shared/utils/constants';
import CategoryFilter from '@shared/components/index/filter/category-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import { UnmanagedPaginationControl } from '@shared/components/index/pagination-controls/pagination-controls';
import { useFilters } from '@shared/hooks/use-filters';
import CardMedia from '@shared/components/index/media-card/media-card';

const HeadquartersIndex = ({ baseUri }) => {
  const data = useStaticQuery(graphql`
    query HeadquartersQuery {
      items: allWordpressWpTtHeadquarters(sort: {fields: title}) {
        nodes {
          title
          uri
          indexCategories
          featuredImage {
            node {
              ...MediaItemFields
            }
          }
        }
      }
    }
  `);
  const items = data.items.nodes;
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters, applyFilters] = useFilters();

  const { categoryFilter } = filters;
  const results = applyFilters(items);

  return (
    <FadeInSection wait={SCROLL_IN_OFFSET.fade_in_offset + SCROLL_IN_OFFSET.hero_offset}>
      <IndexLayout>
        <FilterMenu sticky={true}>
          <ResultLabel size={results.length} />
          <CategoryFilter
            value={categoryFilter}
            onFilterChange={(value) => {
              setCurrentPage(1);
              setFilters({ categoryFilter: value });
            }}
            items={items}
            getItemValue={(item) => item.indexCategories.map((item) => he.decode(item))}
            multipleCategories={true}
          />
        </FilterMenu>
        <IndexContent>
          <UnmanagedPaginationControl
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            renderCallback={(item) => (
              <>
                {item?.map((item, index) => (
                  <CardMedia
                    key={index}
                    title={item.title}
                    image={item.featuredImage.node}
                    uri={item.uri}
                    layout={'thirds'}
                  />
                ))}
              </>
            )}
            items={results}
            pageSize={12}
          />
        </IndexContent>
      </IndexLayout>
    </FadeInSection>
  );
};

export default HeadquartersIndex;
